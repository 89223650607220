@import '~nes.css/css/nes.min.css';

/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  font-family: 'Press Start 2P', cursive;
  background: url('./background.png') no-repeat center center fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.header button {
  background-color: black;
  color: red;
  border: none;
  padding: 10px 20px;
  font-family: 'Press Start 2P', cursive;
  cursor: pointer;
  transition: background-color 0.3s;
}

.header button:hover {
  background-color: red;
  color: black;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-image {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.header-image img {
  max-width: 100%;
  height: auto;
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.main-content img {
  max-width: 100%;
  height: auto;
}

.text-content {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
}

.bottom-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.bottom-buttons button {
  background-color: black;
  color: red;
  border: none;
  padding: 10px 20px;
  font-family: 'Press Start 2P', cursive;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bottom-buttons button:hover {
  background-color: red;
  color: black;
}
